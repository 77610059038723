

















































































































































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';

import {
  IEmbedCode,
} from '@/interfaces';
import {
  dispatchGetEmbedCodes,
} from '@/store/affi/actions';
import {
    readEmbedCodes,
    readImpersonated,
} from '@/store/affi/getters';
import { readHasAdminAccess } from '@/store/main/getters';
import { ttt } from '@/nutils';

@Component
export default class EditBrand extends Vue {
  public ttt = ttt;
  public valid = false;
  public orderStatus: string = '';
  public emailBeforeFooter: string = '';
  public emailUntilFinish: string = '';
  public head: string = '';
  public snippet: string = '';


  public async mounted() {
    await dispatchGetEmbedCodes(this.$store);
    this.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public reset() {
    this.orderStatus = '';
    this.emailBeforeFooter = '';
    this.emailUntilFinish = '';
    this.head = '';
    this.snippet = '';
    if (this.embedcode) {
      this.orderStatus = this.embedcode.order_status;
      this.emailBeforeFooter = this.embedcode.email_before_footer;
      this.emailUntilFinish = this.embedcode.email_until_finish;
      this.head = this.embedcode.head;
      this.snippet = this.embedcode.snippet;
    }
  }

  get embedcode() {
    const imp = readImpersonated(this.$store);
    const ecs = readEmbedCodes(this.$store)
        .filter( (b) => ( imp === -1 || b && b.owner_id === imp ) );
    return ecs[0];
  }

  get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
  }

  public openGDPR() {
    window.open('https://www.together-commerce.com/gdpr-requirements-for-merchants', '_blank');
  }

  public openVideo() {
    window.open('https://youtu.be/oP3rKaMxUvM');
  }
}
